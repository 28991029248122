.participant-details {
    margin-top: 5px;
}

.participant-details .ant-collapse-header-text {
    color: var(--color-primary);
    font-weight: bold;
}

.rbc-time-slot {
    min-width: 68.6719px;
}

.rbc-event-label {
    font-size: 65%;
    display: none;
}

.timeSlot {
    margin-top: 50%;
}

.TimeNewBooking {
    font-size: 14px;
    font-weight: 500;
    position: relative;
}

.TimeNewBooking {
    border-radius: 2px;
}

.TimeNewBooking {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    line-height: 1;
    font-size: 12px;
}

.TimeNewBooking.AllDay {
    height: 15px;
}

.TimeDelete {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: absolute;
    right: 0;
    top: 0;
}

.calender-event-list {
    display: block;
    padding-top: 13px;
}

.calender-table-item-time,
.calender-table-item-checkbox {
    width: 100%;
    display: "flex";
}

.calendar-table-item-delete {
    right: 0px;
    position: absolute;
    margin-top: -43px;
    margin-right: 6px;
    color: red;
    font-weight: 800;
    font-size: 13px;
}

.calendar-table-item {
    display: block;
}

.calender-table-item-fullday-description {
    color: grey;
    font-style: italic;
}

.calender-table-item-time-description {
    color: grey;
}


/* Schedule */
.calendar-table-item {
    border: 1px solid lightgrey !important;
    font-size: 13px;
    margin-left: 15px;
    margin-top: 10px;
    padding: 9px;
    border-radius: 5px;
}

.calender-table-item-date {
    font-weight: 500;
    padding: 5px;
}

.calender-table-item-time-description {
    padding: 5px;
}