.pricingBox {
    min-height: 720px;
    margin-right: 20px;
    text-align: center;
    border: 2px solid var(--color-primary);
}

.feature {
    text-align: left;
    padding-left: 40px;
}


.pricing-features span {
    float: left;
    margin-left: 20px;
}

.pricing {
    margin-top: -15px;
    margin-bottom: 5px;

}

.pricingBox button {
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 150px;

}

.feature-box-basic-options span.ant-radio+* {
    font-size: 13px;
}

.basic-feature-table {
    min-height: 175px;
}

.basic-feature-table .ant-table-thead>tr>th {
    color: black !important;
    background-color: #FAFAFA !important;
}

.basic-feature-table .ant-table-thead>tr>th,
.pricingBox .ant-table-tbody>tr>td,
.pricingBox .ant-table tfoot>tr>th,
.pricingBox .ant-table tfoot>tr>td {
    padding: 3px 3px;
}

.pricingBox .ant-table-wrapper .ant-table-cell,
.pricingBox .ant-table-wrapper .ant-table-thead>tr>th,
.pricingBox .ant-table-wrapper .ant-table-tbody>tr>th,
.pricingBox .ant-table-wrapper .ant-table-tbody>tr>td,
.pricingBox .ant-table-wrapper tfoot>tr>th,
.pricingBox .ant-table-wrapper tfoot>tr>td {
    padding: 3px 3px;
}

.descriptionContainer {
    min-height: 132px;
}