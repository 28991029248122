.PagePublicSite .ant-form-item {
    margin-bottom: 20px;
}

.PagePublicSite li>p:first-child {
    display: inline;
}

.PagePublicSite img {
    max-width: 100%;
}