/* Site Layout */
.site-layout-background-white {
    background: #fff;
}

.site-layout-background-grey {
    background: #f0f2f5;
}

/* Menu & Header */
.App-header {
    background-color: var(--color-primary);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ant-layout-header,
.ant-menu.ant-menu-dark {
    background-color: var(--color-primary);
}

/* Menu selected/hover */
.onefront-menu-selected>.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-selected.onefront-menu-selected:hover,
.ant-menu-dark .ant-menu-item-selected.onefront-menu-selected,
.onefront-menu-selected {
    background-color: #71a7d3;
    color: white;
}

/* Submenu (features) selected */
.ant-menu-dark.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected {
    color: white;
}

/* Hover state submenu */
.ant-menu-dark .ant-menu-item,
.ant-menu-dark>.ant-menu .ant-menu-item,
.ant-menu-dark .ant-menu-submenu-title,
.ant-menu-dark>.ant-menu .ant-menu-submenu-title {
    color: white;
}

/* Hover state menu */
.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.ant-menu-dark>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)>.ant-menu-submenu-title:hover,
.ant-menu-dark>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)>.ant-menu-submenu-title:hover {
    background-color: #71a7d3;
    color: white;
}

/* Not white on selected*/
.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
    color: rgba(255, 255, 255, 0.65);
}

/* left side menu selected */
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: #234e70;
    color: white;
}

.menu-container {
    display: block;
    height: 80px;
}

.ant-layout-header {
    height: 88px;
}

/* Logo */
.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.logo {
    float: left;
    width: 123px;
    height: 83px;
    margin: 2px 11px 8px 0;
    color: white;
    font-size: 20px;
    background-image: url("../logo_small.png");
    background-size: cover;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>a .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
    padding: 12px 20px;
    border-radius: 0px;
}

.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-dark>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: white;
}

.menu-right-align {
    float: right;
}

@media screen and (min-width: 880px) {
    .mobile-menu-drawer {
        display: none !important;
    }
}

.mobile-menu-drawer {
    padding-top: 10px;
}

/* .mobile-menu-drawer svg {
    margin-top: -24px;
    margin-left: -13px;
    position: absolute;
} */

@media screen and (max-width: 880px) {

    .ant-menu-overflow-item-rest.ant-menu-submenu {
        visibility: hidden !important;
    }

    .hideOnMobile {
        visibility: hidden !important;
    }
}


.mobile-navigation-item-selected {
    font-weight: bold;
}

/* Footer */
.ant-layout-footer {
    background: var(--color-primary);
    color: white;
}


/* Booky Box */
.bookyBox {
    background: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.leftMenu {
    background: white;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.leftMenu .ant-menu-item {
    padding-left: 10px;
}

.pageHeader {
    padding-left: 0px;
}

.bookyBox a,
.bookyBox a:visited,
.bookyBox a:active {
    color: #1890ff;
}

.bookyboxcenter {
    margin: auto;
    width: 840px;
}

@media screen and (max-width: 880px) {
    .bookyboxcenter {
        width: 100%;
        margin: 0 auto;
        border-radius: 0px;
    }
}

.cookieCategoryContainer {
    padding-bottom: 20px;
}

.cookieAccept {
    gap: 15px;
}