/* Tip Tap Html Editor */
.tiptap {
    min-height: 200px;
    border: 1px solid #d9d9d9;
    padding: 5px;


    >*+* {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 21px;
    }

    h3 {
        font-size: 17px;
    }

    li>p:first-child {
        display: inline;
    }
}

.bubble-menu {
    display: flex;
    background-color: #0D0D0D;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
        border: none;
        background: none;
        color: #FFF;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}

.floating-menu {
    display: flex;
    background-color: #0D0D0D10;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
        border: none;
        background: none;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}