/* Table */
.admin-table {
    margin-top: -30px;
}

.admin-table .ant-table-pagination.ant-pagination {
    margin: 0px 0px 8px;
}

.admin-table .ant-table-tbody>tr>td,
.admin-table .ant-table tfoot>tr>th,
.admin-table .ant-table tfoot>tr>td {
    padding: 10px 10px;
    border-radius: 0px;

}

/* .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0px !important;
} */

.ant-table-thead>tr>th {
    position: static !important
}



.ant-table-thead>tr>th {
    background: var(--color-primary);
    color: white;
}

.ant-table-thead th.ant-table-column-sort {
    background: var(--color-primary) !important;
}

.event-showparticipants-table .ant-table-thead>tr>th {
    background: #fafafa;
    color: rgba(0, 0, 0, .88);
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #71a7d3
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    background-color: var(--color-primary);
    color: white;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    color: white;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: black;
}

.ant-table table.version-history-table {
    width: 500px;
}

.version-history-table td,
.version-history-table th {
    border: 1px solid grey;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #fafafa !important;
}