body {
    line-height: 1.5715;
}

.BookyTitleWithIcon-Icon svg:first-of-type {
    font-size: 23px;
    vertical-align: middle;
}

.BookyTitleWithIconDivider {
    margin-top: 15px;
}

/* Right content */
.BookyTitleWithIcon-RightContent {
    float: right;
    color: #1890ff;
}

.memberlist-filter {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 280px
}

.memberlist-filter-small {
    width: 200px
}

/* Calendar options coloring */
.accepted {
    color: green
}

.tentative {
    color: orange
}

.rejected {
    color: red
}

.no-replies,
.no-notes,
.cancelled-member,
.not-payed {
    color: lightgrey;
}

.cancelled-member {
    color: #a0a0a0;
}

/* ArrowBox Start-Page */
.arrowBox,
.arrowBox h1 {
    background: var(--color-primary);
    color: white;
}

.arrow {
    padding-top: 50px;
}

/* Create form */
.pro-link a,
.pro-link a:hover,
.pro-link a:visited {
    color: #1890ff;
    padding-right: 5px;
}

/* Create/Edit even form */
.bookybox-page-layout .bookyBox {
    margin-top: 20px;
}

/* Send mail event details */
.bookyCollapse {
    background-color: #ffff
}

.bookyCollapse .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
    flex: inherit
}

.bookyCollapse .ant-form-item-explain-error {
    float: left;
}

.bookyCollapse .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
    flex: inherit;
}

/* Google Button Icon */
.custom-google-icon {
    margin-top: -6px;
}

.footer div {
    color: white;
}

.footer a {
    color: white;
}

.iconWindow,
.elevatedWindow {
    text-align: center;
}

/* Event answer  */
.event-radio-answers label {
    width: 140px;
    text-align: center;

}

.event-radio-answers .ant-radio-button-wrapper-checked {
    background: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    color: white;
}

.image-selected {
    box-shadow: 0px 12px 22px 1px #333;
}

/* The hero image */
.hero-image {

    /* Set a specific height */
    height: 200px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

/* The hero image */
.company-logo {

    /* Set a specific height */
    background-repeat: no-repeat;
    background-size: auto;
    max-height: 120px;
    max-width: 500px;

}

/* Place text in the middle of the image */
.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

/* ReplyTable */
.reply-table {}

.date-header {
    /* width: 35px; */
}

.reply-table th:not(:first-of-type) {
    border: 1px solid transparent;
    cursor: pointer;
}

.reply-table th:not(:first-of-type):hover {
    border: 1px solid lightgrey;
    cursor: pointer;
}

.date-day {
    font-weight: bold;
    font-size: 22px;
}

.reply-answer,
.date-header {
    text-align: center;
}

.date-hasMostYesCount {
    color: gold;
}

.date-yescount {
    font-size: 80%;
}



.PhoneInputInput {
    border: 0;
}

/* Integration card*/
.integration-card {
    min-height: 570px;
    border: 1px solid #cecece;
}

.integration-card img {
    height: 104px;
    width: 308px;
    padding: 10px;
}

.cookie-manager .bg-blue-500 {
    background-color: var(--color-primary) !important;
}

.cookie-manager .border-blue-500 {
    border-color: var(--color-primary) !important;
}

.cookie-manager .text-blue-500 {
    color: var(--color-primary) !important;
}

.cookieContainer {
    margin: 0px 0px 20px 0px;
}

.cookieAccept {
    display: flex;
    margin-bottom: 10px;
}

.cookieAccept button {
    width: 100%;
}

.cookieSettings {
    width: 100%;
}