.bookyStartPage {
    background-color: white !important;
    margin-bottom: 0px;
}

.create-account-box .schedule-title {
    font-size: 45px;
}

.create-account-box {
    text-align: left;
    color: #606469;
    font-size: 20px;
    line-height: 30px;
}

.create-account-box .free-service {
    border-left: 2px solid orange;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

.create-account-box button {
    border-radius: 20px;
    width: 200px;
}

.iconWindow {
    padding-bottom: 200px;
    padding-Top: 40px;
    margin-Bottom: 20px;
    margin-Left: -50px;
    margin-Right: -50px;
}


.elevatedWindow {
    min-height: 200px;
    font-size: 15px;
    font-size: 20px;
    margin: 25px 0px 25px 0px;
}

.elevatedWindow ul {
    text-align: left;
    font-size: 17px;
}

.elevatedWindow .anticon {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 25px;
}

.box-feature {
    background-color: white;
    border-radius: 50px;
    min-height: 180px;
    padding: 20px;
    font-size: 14px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
}

.box-feature .feature-icon {
    color: var(--color-secondary);
    margin-top: 10px;

}

.box-feature .feature-icon .anticon {
    font-size: 30px;
    margin-right: 10px;
}

.box-feature .header {
    color: var(--color-primary);
}

.box-feature .description {
    color: rgba(0, 0, 0, 0.88);
}

.eventbookyfeatures {
    background-color: white;

    padding-bottom: 25px;
}

.feature-container {
    display: flex;
    padding-bottom: 10px;
}

.feature-icon {
    font-size: 30px;
}

.feature-title {
    margin-left: 30px;
    text-align: left;
}

.feature-headline {
    font-weight: bold;
}

.schedule-title,
.schedule-title-boxes {
    margin-top: 30px;
    font-size: 50px;
}

.elevatedWindow {
    color: #f0f2f5;
    background-color: #f0f2f5;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media screen and (max-width: 880px) {

    .Page {
        padding-left: 20px;
        padding-right: 20px;
    }

    .bookyStartPage .ant-col,
    .ant-col-8,
    .ant-col-16,
    .ant-col-15,
    .bookyBox {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
    }

    .ant-typography.schedule-title-boxes {
        font-size: 14px;
    }

    .feature-container {
        padding-bottom: 25px;
    }

    .create-account-box {
        padding-left: 0px;
    }



    .iconWindow,
    .elevatedWindow {
        margin-left: 0px;
        margin-right: 0px;
        background-color: white;
    }

    .box-feature {
        margin-top: 15px;
    }

    .bookyBox.pricingBox {
        margin-right: 0px;
        margin-bottom: 50px;
    }

    .iconWindow div {
        flex: 0 0 23%;
        max-width: 23%;
    }

    .eventbookyfeatures {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 40px;
        padding-right: 20px;
    }

    .pricing-features {
        margin-left: 40px;
    }

    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
        width: 100%;
        height: 55px;
    }
}

.startPageImg img {
    width: 700px;
}

@media screen and (max-width: 900px) {

    .startPageImg {
        display: none;
    }
}