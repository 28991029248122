/* Text */
.descriptionText {
    font-size: 80%;
}

/* Buttons*/
.button-fullwidth {
    width: 100%
}

.ant-btn-primary {
    border-color: var(--color-primary);
    background: var(--color-primary);
    box-shadow: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: #71a7d3;
    border-color: #71a7d3;
}

/* Checkbox vertical*/
.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

/* Links */
a {
    color: var(--color-primary);
}

.App-link {
    color: #61dafb;
}

.feature-card {
    min-height: 300px;
}

.feature-card-icon {
    /* float:left; */
    /* font-size: 20px; */
}

.feature-card-title {
    align-items: center;
    display: flex;
    justify-content: center
}