.onefront-modal .ant-modal-header,
.onefront-modal .ant-modal-content {
    background: #FAFAFA;
}

.onefront-modal .ant-modal-body .ant-form-item {
    margin-bottom: 9px;
}

.onefront-modal .PhoneInputInput,
.onefront-modal .PhoneInputInput--focus {
    border: 0px;
    outline: none;
}