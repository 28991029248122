/* Page */
.Page {
    margin-bottom: 40px;
}

.PagePublicSite {
    margin-top: 80px;
}

.PagePublicSite .bookyboxcenter {
    width: 640px;
    background: #f0f2f5;
    padding: 50px;

}

.PagePublicSite .ant-input {
    border-radius: 6px;
    line-height: 2.3;
}

.PagePublicSite .form-item-label {
    font-family: Open Sans, sans-serif;
    font-size: 19px;
    font-weight: 400;
}

.PagePublicSite .saveButton {
    width: 100px;
    height: 45px;
}

.PageSmall .bookyboxcenter {
    width: 640px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
}

.PageSmall form {
    width: 600px;
    padding-left: 20px;
    padding-right: 20px;
}

.PageWide .bookyboxcenter {
    width: 1040px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
}

.PageWide .bookyboxleft {
    width: 1040px;
}

.PageWide form {
    width: 1000px;
    padding: 0 0px 40px 40px;
    margin: auto;
}

@media screen and (max-width: 880px) {
    .PageWide form {
        width: 100%;
        margin: auto;
        padding: 0px;
    }
}

.PageWide .bookyBox {
    margin-top: 20px;
}

.PageWide form .ant-form-item,
.PageWide .ant-row {
    width: 100%;
}